import React from 'react';
import { compose } from 'recompose';
import { Location } from '@reach/router';
import Layout from '../components/layout';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext
} from '../components/Session';

import Documents from '../components/Documents/Documents';

const DocumentPageBase = () => (
  <div>
    <h1>Document Page</h1>
    <p>The Document Page is accessible by every signed in user.</p>
    <Location>
      {({ location }) => {
        const documentId = location.pathname.split('/').pop();
        return <p>documentId is {documentId}</p>;
      }}
    </Location>
    <AuthUserContext.Consumer>
      {authUser => <Documents authUser={authUser} />}
    </AuthUserContext.Consumer>
  </div>
);

const condition = authUser => !!authUser;

const DocumentPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(DocumentPageBase);

export default () => (
  <Layout>
    <DocumentPage />
  </Layout>
);
